 <template>
  <div id="page">

  <PageHeader :pageTitle="pageTitle" :pageDescription="pageDescription"></PageHeader>

    <v-row dense>

        <v-col class="col-6">
          Card
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="col-6">
          Card
        </v-col>

      </v-row>
  </div>
</template>


<script>

import apiRequest from "@/mixins/apiRequest"
import PageHeader from '@/components/ui/PageHeader.vue'

export default {

  name: 'index',

  components: {
    PageHeader,
  },

  data () {
    return {
      pageTitle: 'Produkt-Kategorien',
      pageDescription: `<p>Hier werden die Kategorien für die Produkte (Kampagnen) verwaltet.</p>`,
      title: '',
      preloader: false,
      data: []
    }
  },

  created: function () {
    /* this.getData(); */
  },

  methods: {

    async getData() {

      /* this.preloader= true;
      let response = await this.getRequest('companies/'+this.$route.params.id); // await data from mixin
      this.preloader= false;
      this.data = response.data.data;
      this.title = response.data.data.name; */

    }

  },

  mixins: [apiRequest],

}
</script>